<template>

  <div class="app de">
    <div class="hd">
      <span @click="$router.back()">返回</span>
      <div>实名验证</div>
      <div></div>

    </div>
    <div class="ct">
      <md-field>
        <md-input-item
            ref="name"
            preview-type="text"
            v-model="user.realname"
            title="真实姓名"
            placeholder="请输入您的真实姓名"
            is-title-latent
        ></md-input-item>
        <md-input-item
            ref="id"
            preview-type="text"
            title="身份证号码"
            v-model="user.idnum"
            placeholder="请输入您的身份证号码"
            is-title-latent
        ></md-input-item>
      </md-field>

      <div class="md-example-child-reader">
        <div>身份证正面</div>
        <ul class="image-reader-list">
          <li
              v-if="user.idfront"
              class="image-reader-item"
              :style="{
          'backgroundImage': `url(${user.idfront})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
        }">
            <md-tag
                class="image-reader-item-del"
                size="small"
                shape="quarter"
                fill-color="#111A34"
                type="fill"
                font-color="#fff"
                @click.native="user.idfront=''"
            >
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li v-else class="image-reader-item add">
            <md-image-reader
                name="reader0"
                @select="onReaderSelect"
                @complete="onReaderComplete1"
                @error="onReaderError"
                is-multiple
            ></md-image-reader>
            <md-icon name="camera" size="md" color="#CCC"></md-icon>
            <p>身份证正面</p>
          </li>
        </ul>
      </div>

      <div class="md-example-child-reader">
        <div>身份证反面</div>
        <ul class="image-reader-list">
          <li
              v-if="user.idback"
              class="image-reader-item"
              :style="{
          'backgroundImage': `url(${user.idback})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
        }">
            <md-tag
                class="image-reader-item-del"
                size="small"
                shape="quarter"
                fill-color="#111A34"
                type="fill"
                font-color="#fff"
                @click.native="user.idback=''"
            >
              <md-icon name="close"></md-icon>
            </md-tag>
          </li>
          <li v-else class="image-reader-item add">
            <md-image-reader
                name="reader0"
                @select="onReaderSelect"
                @complete="onReaderComplete2"
                @error="onReaderError"
                is-multiple
            ></md-image-reader>
            <md-icon name="camera" size="md" color="#CCC"></md-icon>
            <p>身份证正面</p>
          </li>
        </ul>
      </div>
      <md-button type="primary" @click="dotj">提交申请</md-button>
    </div>

  </div>

</template>

<script>
export default {
  name: "realname",
  data(){
    return {
      user:{
        realname:'',
        idnum:'',
        idfront:'',
        idback:''
      }
    }
  },
  mounted(){

  },
  methods:{
    async dotj(){
      if(!this.user.realname ) return this.$toast.info('请填写正确的姓名')
      if(!this.user.idnum ) return this.$toast.info('请填写正确的姓名')
      if(!this.user.idfront ) return this.$toast.info('请上传身份证正面')
      if(!this.user.idback ) return this.$toast.info('请上传身份证反面')
      const res = await this.$api.post('/mapi/realname',{...this.user,uid:this.$store.state.userInfo.uid})
      const { success,info,data } = res.data;
      if(success){
        this.$toast.succeed('提交成功请等待审核通过')
        this.$store.commit('setUserInfo', {state:2});
        this.$router.back()
      }else{
        this.$toast.failed(info)
      }


    },
    onReaderSelect(name, {files}) {
      this.$toast.loading('图片读取中...')
    },
    async onReaderComplete1(name, {dataUrl, file}){
      console.log(file)
      this.$toast.hide();
      // 开始上传图片
      let param = new FormData()  // 创建form对象
      param.append('file',file);

      const res = await this.$api.post('/adUpload',param);
      console.log(res.data)
      const { success,msg } = res.data;
      if(success) this.user.idfront = msg

    },
    async onReaderComplete2(name, {dataUrl, file}){
      console.log(file)
      this.$toast.hide();
      // 开始上传图片
      let param = new FormData()  // 创建form对象
      param.append('file',file);

      const res = await this.$api.post('/adUpload',param);
      console.log(res.data)
      const { success,msg } = res.data;
      if(success) this.user.idback = msg

    },
    onReaderError(){

    }
  }

}
</script>

<style scoped lang="scss">
.md-example-child-reader {
  .image-reader-list {
    float:left;
    width :100%;

    .image-reader-item {
      position  :relative;
      float : left;
      width  :23.5%;
      padding-bottom : 23.5%;
      margin-bottom  :2%;
      margin-right : 2%;
      background  :#FFF;
      box-shadow  :0 5px 20px rgba(197, 202, 213, .25);
      box-sizing  :border-box;
      list-style  :none;
      border-radius : 4px;
      background-size : cover;
      overflow  :hidden;

      &:nth-of-type(4n) {
        margin-right : 0
      }

      &.add {
        .md-icon {
          position  :absolute;
          top  :40%;
          left : 50%;
          transform : translate(-50%, -50%);
          opacity : .5;
        }

        p {
          position : absolute;
          top : 50%;
          left  :0;
          width  :100%;
          margin-top : 15px;
          font-size  :22px;
          color : #CCC;
          text-align : center;
        }

        .image-reader-item-del {
          position :absolute;
          top : 0;
          right : 0;
          z-index  :3;
          opacity  :.8;

          .md-icon-close {
            font-size : 24px
          }
        }
      }
    }
  }
}
::v-deep .image-reader-item-del{
  position: absolute;
  top:0;
  right:0
}
</style>
