var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app de"},[_c('div',{staticClass:"hd"},[_c('span',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")]),_c('div',[_vm._v("实名验证")]),_c('div')]),_c('div',{staticClass:"ct"},[_c('md-field',[_c('md-input-item',{ref:"name",attrs:{"preview-type":"text","title":"真实姓名","placeholder":"请输入您的真实姓名","is-title-latent":""},model:{value:(_vm.user.realname),callback:function ($$v) {_vm.$set(_vm.user, "realname", $$v)},expression:"user.realname"}}),_c('md-input-item',{ref:"id",attrs:{"preview-type":"text","title":"身份证号码","placeholder":"请输入您的身份证号码","is-title-latent":""},model:{value:(_vm.user.idnum),callback:function ($$v) {_vm.$set(_vm.user, "idnum", $$v)},expression:"user.idnum"}})],1),_c('div',{staticClass:"md-example-child-reader"},[_c('div',[_vm._v("身份证正面")]),_c('ul',{staticClass:"image-reader-list"},[(_vm.user.idfront)?_c('li',{staticClass:"image-reader-item",style:({
        'backgroundImage': `url(${_vm.user.idfront})`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
      })},[_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){_vm.user.idfront=''}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.onReaderSelect,"complete":_vm.onReaderComplete1,"error":_vm.onReaderError}}),_c('md-icon',{attrs:{"name":"camera","size":"md","color":"#CCC"}}),_c('p',[_vm._v("身份证正面")])],1)])]),_c('div',{staticClass:"md-example-child-reader"},[_c('div',[_vm._v("身份证反面")]),_c('ul',{staticClass:"image-reader-list"},[(_vm.user.idback)?_c('li',{staticClass:"image-reader-item",style:({
        'backgroundImage': `url(${_vm.user.idback})`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
      })},[_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){_vm.user.idback=''}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.onReaderSelect,"complete":_vm.onReaderComplete2,"error":_vm.onReaderError}}),_c('md-icon',{attrs:{"name":"camera","size":"md","color":"#CCC"}}),_c('p',[_vm._v("身份证正面")])],1)])]),_c('md-button',{attrs:{"type":"primary"},on:{"click":_vm.dotj}},[_vm._v("提交申请")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }